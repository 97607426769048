export const useProducts = async () => {
  const storyblokApiOptions = useStoryblokApiOptions();
  const storyblokApi = useStoryblokApi();
  const { localeStoryName, goto, getUrlFromLink } = useUtils();
  const config = await useConfig();
  const storyblokStore = useStoryblokStore();
  const { $store } = useNuxtApp();

  const translatedRootPagePaths = {
    de: "loesungen/",
    en: "solutions/",
  };

  function isSolutionRootPage(story: Object) {
    return (
      story.full_path === translatedRootPagePaths.de ||
      story.full_path === translatedRootPagePaths.en
    );
  }

  async function all() {
    if (!storyblokStore.solutions?.length) {
      const { locale } = useI18n();
      const { data } = await storyblokApi.get("cdn/stories", {
        ...storyblokApiOptions,
        starts_with:
          locale.value === "de"
            ? translatedRootPagePaths.de
            : translatedRootPagePaths.en,
        is_startpage: 0,
        filter_query: {
          component: {
            in: "product",
          },
        },
      });

      storyblokStore.solutions = data.stories;
    }

    return storyblokStore.solutions.map((story) => localeStoryName(story));
  }

  async function find(uuid: string) {
    const link = storyblokStore.links[uuid];

    if (!link?.slug) {
      throw new Error("product not found: uuid=" + uuid);
    }

    const { data } = await storyblokApi.get(`cdn/stories/${link.slug}`, {
      ...storyblokApiOptions,
    });

    return localeStoryName(data.story);
  }

  function show(product: Object) {
    goto(
      getUrlFromLink({
        url: product.full_slug,
        linktype: "story",
      }),
    );
  }

  const linkText = (product: Object) => {
    return config?.more_about_link_text?.replace("{label}", product.name);
  };

  const link = (product: Object) => {
    const { locale } = useI18n();
    return {
      link: {
        url:
          locale.value === "de"
            ? product.full_slug
            : product.translated_slugs.find(
                (translation) => translation.lang === locale.value,
              )?.path,
        linktype: "story",
      },
      linkText: linkText(product),
      button: true,
    };
  };

  const outcomesForFeatureGrid = (product: Object) => {
    return (product?.content || product).outcomes.map((item, i) => {
      return {
        icon: item.image,
        headline: item.headline,
        text: item?.text,
      };
    });
  };

  return {
    all,
    find,
    show,
    link,
    linkText,
    outcomesForFeatureGrid,
    isSolutionRootPage,
  };
};
